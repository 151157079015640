import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormBuilder, ReactiveFormsModule, FormArray, AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CommonModule, formatDate } from '@angular/common';
import { FormSection, FormField } from './form-sections.model'; // Adjust the path as needed
import { CustomFieldsModule } from '../mat-elements/custom-fields/custom-fields.module';
import { HttpService } from 'src/app/core/services/http/http.service';
import { ApiMethod, colorCodes, FailedMessage } from 'src/app/core/services/utils/constants';
import { DynamicFormValidationService } from './dynamic-form-validation.service';
import { DynamicFormFieldComponent } from './dynamic-form-field/dynamic-form-field.component';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AddRowModalComponent } from './add-row-modal/add-row-modal.component';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { CommonService } from 'src/app/core/services/common/common.service';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { CustomCheckboxComponent } from '../mat-elements/custom-fields/custom-checkbox/custom-checkbox.component';



@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CustomFieldsModule,
    DynamicFormFieldComponent,
    AddRowModalComponent,
    PipesModule
  ]
})
export class DynamicFormComponent implements OnInit {
  form: FormGroup;
  @Input() sections: FormSection[] = [];
  isSubmitted: boolean = false;
  sectionKeys: { [key: string]: string } = {};
  @Input() apiEndpoint: string | undefined;
  @Input() apiData: any = null;
  @Input() mode: string = 'add';
  @Input() loadMasterAPI: string = '';
  @Output() formSubmit = new EventEmitter<any>();
  @Input() disableForm: boolean = true; // Add an input to handle disabling the form
  @Input() processAttachment: boolean = false;
  @Input() statusChangeable: boolean = false;
  isFormDisabled: boolean = false;
  dateFormat: any = 'yyyy-MM-dd';
  @ViewChild('dynamicFormField') dynamicFormField!: DynamicFormFieldComponent;
  staticText: any = (textConfiguration as any).default;
  locationdetails: any = [];
  attachmentList: any = {};
  @Input() refID: any = null;
  @Output() copyFromSelectionChanged = new EventEmitter<any>();
  @Output() checkboxChange = new EventEmitter<any>();  
  // @ViewChild('dynamicCheckbox') dynamicCheckbox!: CustomCheckboxComponent;
  @ViewChildren('dynamicCheckbox') dynamicCheckbox!: QueryList<CustomCheckboxComponent>;


  constructor(private fb: FormBuilder, private _http: HttpService,
    private validationService: DynamicFormValidationService, private http: HttpClient, private dialog: MatDialog,
    private _modal: ModalService,
    private _lodash: LodashService, private _loader: LoaderService, private _common: CommonService, private _snackBar: SnackbarService,) {
    this.form = this.fb.group({},);
  }

  ngOnInit() {
    if (this.mode != 'edit' && this.loadMasterAPI) {
      let masterEndpoint = this.sections.find(section => section.key === this.loadMasterAPI)!.meta.dataAPIEndpoint;
      this.fetchMasterData(masterEndpoint);
    } else {
      this.initDynamicForm();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disableForm']) {
      this.isFormDisabled = this.disableForm;
      this.updateFormState(this.disableForm);
    }
  }

  initDynamicForm() {
    this.initializeForm();
    if (this.mode == 'edit' || this.mode == 'view') {
      this.fetchAndLoadFormData(this.apiEndpoint);
    } else if (this.disableForm) {
      this.disableAllFormControls(this.form);
    }

    this.addControlValueChangeListeners();
    //this.dynamicLocation.locationItreation(this.locationdetails, 1);
  }

  private updateFormState(disable: boolean): void {
    const formControls = this.form.controls;
    Object.keys(formControls).forEach(key => {
      const control = formControls[key];
      if (disable) {
        this.disableAllFormControls(control);
      } else {
        this.enableAllFormControls(control);
      }
    });
  }

  onOptionChange(selectedOption: any, sectionKey: any, selectionChangeHandler: Function) {
    // Emit the selected option
    this.copyFromSelectionChanged.emit(selectedOption);

    // Call the provided selection change handler if it exists
    if (selectionChangeHandler) {
      selectionChangeHandler(selectedOption, sectionKey); // Call the handler
    }
  }

  isButtonDisabled(section: any): boolean {
    const { disableActionBtn, enableActionBtnWhen } = section?.meta || {};

    if (enableActionBtnWhen === undefined) {
      // Case when enableActionBtnWhen is not available
      return disableActionBtn === true;
    } else {
      // Case when enableActionBtnWhen is available
      return disableActionBtn === true || !enableActionBtnWhen();
    }
  }

  getFormData(): Observable<any> {
    return this.http.get<any>('assets/data/form-data.json');
  }

  getFormMasterData(apiEndpoint: any): Observable<any> {
    return this.http.get<any>(apiEndpoint);
  }

  getColumnClasses(columns: number | undefined, colspan: number = 1): string {
    colspan = colspan || 1;
    const columnCount = columns ?? 4;
    const colSize = Math.max((12 / columnCount) * colspan, 1);
    return `col-xl-${colSize} col-lg-${colSize} col-md-6 col-sm-12 col-12`;
  }

  fetchMasterData(apiEndpoint: any) {
    this._loader.show();
    this._http.requestCall(apiEndpoint, ApiMethod.GET).subscribe((response: any) => {
      this._loader.hide();
      // this.getFormMasterData(apiEndpoint).subscribe((response: any) => {
      const data = response.data;
      this.sections.find(section => section.key === this.loadMasterAPI)!.meta.data = data;
      this.initDynamicForm();
    },
      (error: any) => {
        this.initDynamicForm();
        console.error('Error during form submission:', error);
      });
  }

  feedDataIntoForm(data: any) {
    this.loadDataIntoForm(data); // Assuming API returns formValues object
    if (this.disableForm) {
      this.disableAllFormControls(this.form);
    }
  }

  fetchAndLoadFormData(apiEndpoint: any) {
    if (apiEndpoint) {
      this._loader.show();
      this._http.requestCall(apiEndpoint, ApiMethod.GET).subscribe((response: any) => {
        this._loader.hide();
        // this.getFormData().subscribe((response: any) => {
        const data = response.data;
        this.feedDataIntoForm(data);
      });
    } else
      this.feedDataIntoForm(this.apiData);
  }

  initializeForm() {
    this.form = this.fb.group({});

    this.sections.forEach((section: FormSection) => {
      const sectionKey = this.formatSectionTitle(section);
      this.sectionKeys[section.title] = sectionKey;

      if (section.sectionType === 'multiple') {
        this.handleMultipleSection(section, sectionKey);
      } else if (section.sectionType === 'root') {
        this.handleRootSection(section);
      } else {
        this.handleNonMultipleSection(section, sectionKey);
      }
    });
  }

  handleMultipleSection(section: FormSection, sectionKey: string) {
    this.form.addControl(sectionKey, this.fb.array([]));

    if (section.meta?.copyDataFrom) {
      this.form.addControl(section.meta?.copyDataFrom?.key, this.fb.control(''));
    }

    if (this.mode !== "edit" && section?.meta?.renderDataOnload) {
      this.loadDataOnSectionLoad(section, sectionKey);
    } else {
      this.addFormGroupToSection(section, true);
    }
  }

  loadDataOnSectionLoad(section: FormSection, sectionKey: string) {
    const data = section?.meta?.data || [];
    const dataLength = data.length;

    if (dataLength) {
      for (let i = 0; i < dataLength; i++) {
        this.addFormGroupToSection(section);
        const formArray = this.form.get(sectionKey) as FormArray;
        const group = formArray.at(i) as FormGroup;
        const control = group.get('id');
        const item = data[i] || {};

        if (control && item.hasOwnProperty('id')) {
          control.setValue(item['id']);
        }
      }
    }
  }

  handleRootSection(section: FormSection) {
    section.fields.forEach((field: any) => {
      const control = this.createFormControl(field);
      this.form.addControl(field.name, control);
      this.addInputDropdownControl(field, this.form, this.fb.control('', []));

      if (field.apiEndpoint && !field.dependsOn) {
        this.fetchOptionsFromApi(field, section);
      } else if (field.dependsOn) {
        this.addDependentFieldListener(field, this.form, section);
      }
    });
  }

  handleNonMultipleSection(section: FormSection, sectionKey: string) {
    const sectionGroup = this.fb.group({});

    section.fields.forEach((field: any) => {
      const control = this.createFormControl(field);
      sectionGroup.addControl(field.name, control);
      this.addInputDropdownControl(field, sectionGroup, this.fb.control('', []));

      if (field.apiEndpoint && !field.dependsOn) {
        this.fetchOptionsFromApi(field, section);
      } else if (field.dependsOn) {
        this.addDependentFieldListener(field, sectionGroup, section);
      }
    });

    this.form.addControl(sectionKey, sectionGroup);
  }

  createFormControl(field: any) {
    const validatorsArray = field?.validators?.map((v: any) => v.validator) || [];
    let defaultValue:any = '';

    if(field.type === 'status_toggler') {
      defaultValue = true;
    } else if(field.type === 'hidden') {
      defaultValue = field?.meta?.defaultValue ?? "";
    }
    
    return this.fb.control(defaultValue, validatorsArray);
  }


  loadDataIntoForm(data: any, isReload?: boolean) {
    this.sections.forEach(section => {
      if (section.fields) {
        const sectionKey = this.formatSectionTitle(section);
        if (section.sectionType == 'multiple') {
          this.loadFormForMultipleSection(section, data, sectionKey, isReload);
        } else if (section.sectionType == 'root') {
          this.loadFormForRootSection(section, data);
        } else {
          this.loadFormForNonMultipleSection(section, data, sectionKey);
        }
      }
    });
  }

  private loadFormForMultipleSection(section: FormSection, data: any, sectionKey: string, isReload?: boolean) {
    const formArray = this.form.get(sectionKey) as FormArray;
    const dataLength = (data[sectionKey] || []).length;

    this.updateMetaDataForSection(section, data, sectionKey);
    this.syncFormArrayWithData(formArray, dataLength, section, isReload);

    (data[sectionKey] || []).forEach((item: any, index: number) => {
      this.populateFormGroupWithData(formArray, item, index, section);
    });

    this.handlePercentageCalculation(section);
  }

  private updateMetaDataForSection(section: FormSection, data: any, sectionKey: string) {
    if (this.mode == 'edit' && section.meta?.renderDataOnload) {
      section.meta.data = data[sectionKey] || section.meta.data;
    }
  }

  private syncFormArrayWithData(formArray: FormArray, dataLength: number, section: FormSection, isReload?: boolean) {
    if (formArray.length && dataLength) {
      if (isReload && formArray.length > dataLength) {
        this.removeExtraFormGroups(formArray, dataLength);
      }
      this.addMissingFormGroups(formArray, dataLength, section);
    }
  }

  private removeExtraFormGroups(formArray: FormArray, dataLength: number) {
    while (formArray.length > dataLength) {
      formArray.removeAt(formArray.length - 1); // Removes the last control
    }
  }

  private addMissingFormGroups(formArray: FormArray, dataLength: number, section: FormSection) {
    while (formArray.length < dataLength) {
      this.addFormGroupToSection(section);
    }
  }

  private populateFormGroupWithData(formArray: FormArray, item: any, index: number, section: FormSection) {
    const group = formArray.at(index) as FormGroup;
    section.fields.forEach((field: any) => {
      const control = group.get(field.name);
      if (control && item.hasOwnProperty(field.name)) {
        control.setValue(item[field.name]);
        this.setInputDropdownValue(field, group, item);
        this.resetEmptyFlagIfNeeded(group);
        this.updateCheckboxFieldIfNeeded(field, control);
      }
    });
  }

  private resetEmptyFlagIfNeeded(group: FormGroup) {
    if (group.get('isEmptyFlag')?.value === true) {
      group.get('isEmptyFlag')?.setValue(false);
    }
  }

  private updateCheckboxFieldIfNeeded(field: any, control: any) {
    if (field.type === 'checkbox') {
      control.markAsTouched(); // Optionally mark it as touched
      control.updateValueAndValidity(); // Ensure the control is updated
    }
  }

  private handlePercentageCalculation(section: FormSection) {
    if (section?.meta?.triggerOverallPercentageCalculation) {
      section.meta.disableActionBtn = true;
    }
  }

  private loadFormForRootSection(section: FormSection, data: any) {
    section.fields.forEach((field: any) => {
      const control = this.form.get(field.name);
      if (field.type == 'phone_field') {
        this.handlePhoneField(field, control, data);
      }
      this.setInputDropdownValue(field, this.form, data);
      this.setFieldValue(field, control, data);
    });
  }

  private handlePhoneField(field: any, control: any, data: any) {
    if (control && data.hasOwnProperty(field.altName)) {
      control.setValue(data[field?.altName]);
    }
  }

  private setFieldValue(field: any, control: any, data: any) {
    if (control && data.hasOwnProperty(field.name)) {
      control.setValue(data[field.name]);
    }
  }

  private loadFormForNonMultipleSection(section: FormSection, data: any, sectionKey: string) {
    section.fields.forEach(field => {
      const control = this.form.get(`${sectionKey}.${field.name}`);
      if (control && data.hasOwnProperty(sectionKey) && data[sectionKey].hasOwnProperty(field.name)) {
        this.handleFieldTypes(field, control, data, sectionKey);
      }
    });
  }

  private handleFieldTypes(field: any, control: any, data: any, sectionKey: string) {
    if (field.type === 'file') {
      control.setValue(data[sectionKey][field.name]); // Handle file input separately
    } else if (field.type === 'location_field') {
      this.handleLocationField(field, data, sectionKey);
    } else if (['phone_field', 'input_dropdown'].includes(field.type)) {
      this.handlePhoneAndDropdownField(field, control, data, sectionKey);
    } else {
      control.setValue(data[sectionKey][field.name]);
    }
  }

  private handleLocationField(field: any, data: any, sectionKey: string) {
    setTimeout(() => {
      this.dynamicFormField?.dynamicLocation.setLocationDetails(data[sectionKey][field.name]);
    }, 100);
  }

  private handlePhoneAndDropdownField(field: any, control: any, data: any, sectionKey: string) {
    let altControl = this.form.get(`${sectionKey}.${field.altName}`);
    if (altControl && sectionKey !== undefined && data[sectionKey] && field.altName && data[sectionKey].hasOwnProperty(field.altName)) {
      altControl.setValue(data[sectionKey][field.altName]);
    }
    control.setValue(data[sectionKey][field.name]);
  }


  setInputDropdownValue(field: any, group: any, data: any) {
    if (field.type === 'input_dropdown' && data.hasOwnProperty(field.altName))
      group.get(field.altName)?.setValue(data[field.altName]);
  }

  // Step 2: Add value change listeners for all fields with controlSection logic
  addControlValueChangeListeners(): void {
    this.sections.forEach(section => {
      section.fields.forEach((field: any) => {
        if (field.meta?.controlSection) {
          // Fetch controls for both 'single' and 'multiple' sections
          const controls = this.getControlsForSection(section, field);

          // Add value change listeners
          controls.forEach(control => {
            if (control) {
              control.valueChanges.subscribe(value => {

                if (value) {
                  this.handleControlSection(value, field.meta.controlSection);
                }
              });
            }
          });
        }
      });
    });
  }

  // Helper function to get controls for 'single' and 'multiple' sections
  getControlsForSection(section: any, field: any): AbstractControl[] {
    let controls: AbstractControl[] = [];

    // Handle 'single' sectionType (FormGroup)
    if (section.sectionType === 'single') {
      const control = this.form.get(`${section.key}.${field.name}`);
      if (control) {
        controls.push(control);
      }

      // Handle 'multiple' sectionType (FormArray)
    } else if (section.sectionType === 'multiple') {
      const formArray = this.form.get(section.key) as FormArray;
      formArray?.controls?.forEach((formGroup: any) => {
        const control = formGroup.get(field.name);
        if (control) {
          controls.push(control);
        }
      });

    } else {
      const control = this.form.get(field.name);
      if (control) {
        controls.push(control);
      }
    }

    return controls;
  }


  // Step 3: Handle controlSection logic generically
  handleControlSection(controlValue: any, controlSection: any): void {
    const valueToCheck = typeof controlValue === 'object' && controlValue !== null
      ? controlValue?.name
      : controlValue;


    if (valueToCheck) {
      controlSection.sectionKeys.forEach((sectionKey: string) => {
        const section = this.sections.find(sec => sec.key === sectionKey);

        if (section) {
          controlSection.actions.forEach((action: string) => {
            switch (action) {
              case 'disable':
                this.disableSection(section);
                break;
              case 'clear':
                this.clearSection(section);
                break;
              // Add more actions here if necessary
            }
          });
        }
      });
    }
  }

  // Step 4: Disable all fields in a section
  disableSection(section: any): void {
    section.fields.forEach((field: any) => {
      const control = this.form.get(field.name);
      if (control && !control.disabled) {  // Check if not already disabled
        control.disable();
      }
    });
  }

  // Step 5: Clear all fields in a section
  clearSection(section: any): void {
    if (section.sectionType == 'multiple') {
      if (!this.isFirstGroupEmpty(section.key)) {
        this.removeAllExceptFirst(section.key);
        this.emptyFormArrayValues(section.key);
      }
    } else {
      section.fields.forEach((field: any) => {
        const control = this.form.get(field.name);
        if (control?.value) {  // Clear only if there is a value
          control.reset();  // Clears the control value
        }
      });
    }
    section.meta.disableActionBtn = false;
  }

  removeAllExceptFirst(sectionKey: string): void {
    const sectionArray = this.form.get(sectionKey) as FormArray;

    if (sectionArray && sectionArray.length > 1) {
      // Remove elements from the end to avoid index shifting issues
      while (sectionArray.length > 1) {
        sectionArray.removeAt(sectionArray.length - 1);
      }
    }
  }

  formatSectionTitle(section: any): string {
    if (section?.key) {
      return section?.key;
    }

    return section?.title?.toLowerCase() // Convert the entire string to lowercase first
      .replace(/\s(.)/g, (match: any, group1: any) => group1.toUpperCase()) // Capitalize the first letter after each space
      .replace(/\s+/g, ''); // Remove all spaces
  }

  getFieldNameWithSection(fieldName: string, sectionKey: string, sectionType: string = 'single'): string {
    return (sectionType == 'multiple') ? `${sectionKey}.${fieldName}` : fieldName;
  }

  onAddMore(section: any): void {
    const isInlineEdit = section?.meta?.inlineEdit;

    if (isInlineEdit) {
      // Handle inline edit logic here
      this.addFormGroupToSection(section);
    } else {
      // Open the dialog for non-inline edit
      this.openAddRowDialog(section);
    }
  }

  addFormGroupToSection(section: FormSection, isAdd?: boolean): void {
    const sectionKey = section?.key ?? this.formatSectionTitle(section);
    const sectionArray = this.form.get(sectionKey) as FormArray;

    // Create a new form group
    const newGroup: any = this.fb.group({
      isEmptyFlag: this.fb.control(true), // Add a control to track if the group is empty
    });

    // Add controls to the form group
    section?.fields.forEach((field) => {
      const controlName = field.name;
      const defaultValue = (isAdd && field.type === 'status_toggler') ? true : (field?.defaultValue ?? '');
      const validatorsArray = field?.validators?.map((v) => v.validator) ?? [];
      const control = this.fb.control(defaultValue, validatorsArray);
      newGroup.addControl(controlName, control);
      this.addInputDropdownControl(field, newGroup, this.fb.control('', []));

      if (field.apiEndpoint && !field.dependsOn) {
        this.fetchOptionsFromApi(field, section);
      }

      // Add listener for dependent fields
      if (section?.meta?.inlineEdit !== false && field.dependsOn) {
        this.addDependentFieldListener(field, newGroup, section);
      }

      // Update isEmptyFlag control when a field is changed
      // control.valueChanges.subscribe(value => {
      //   const isEmptyFlag = this.checkIfFormGroupIsEmpty(newGroup);
      //   newGroup.get('isEmptyFlag')?.setValue(isEmptyFlag);
      // });
    });

    // Add the new group to the section array
    sectionArray.push(newGroup);
  }

  addInputDropdownControl(field: any, group: FormGroup, control: any) {
    if (field.type == "phone_field" || field.type == "input_dropdown") {
      group.addControl(field?.altName, control);
    }
  }

  addDependentFieldListenerValueChanges(field: any, group: any, section: any, dependsOn: string): void {

    const parentControl = group.get(dependsOn);

    if (parentControl) {
      parentControl.valueChanges.subscribe((parentValue: any) => {
        if (parentValue) {
          // If a parent value is selected, fetch options for the dependent field
          this.fetchOptionsFromApi(field, section, parentValue, group);
        } else {
          // Reset the dependent field options and clear the control value
          const dependentControl = group.get(field.name);
          dependentControl?.setValue(null);
          field.options = [];
        }
      });
    }

  }

  // Listener for changes in dependent fields
  addDependentFieldListener(field: any, group: any, section: any): void {
    if (Array.isArray(field.dependsOn)) {
      for (const dependsOn of field.dependsOn) {
        this.addDependentFieldListenerValueChanges(field, group, section, dependsOn)
      }
    } else {
      this.addDependentFieldListenerValueChanges(field, group, section, field.dependsOn)
    }

  }

  // Helper method to check if a FormGroup is empty
  private checkIfFormGroupIsEmpty(group: FormGroup): boolean {
    const obj: any = group.getRawValue();

    const hasValue = Object.keys(obj).some((key) => {
      if (key === 'isEmptyFlag') {
        return false; // Skip this key
      }
      return !group?.get(key)?.value; // Check if the value is not empty
    });

    return hasValue;

    // return Object.values(group).every((control: any) => {
    //   return !control.value || control.disabled;
    // });
  }

  isFirstGroupEmpty(sectionKey: string): boolean {
    const sectionArray = this.form.get(sectionKey) as FormArray;
    const firstGroup = sectionArray.at(0) as FormGroup;
    return firstGroup.get('isEmptyFlag')?.value ?? false; // Assuming 'isEmptyFlag' is true by default
  }

  clearFirstIndex(sectionKey: string): void {
    const sectionArray = this.form.get(sectionKey) as FormArray;

    if (sectionArray.length > 0) {
      sectionArray.removeAt(0); // Removes the first FormGroup/FormControl in the FormArray
    }
  }

  hasAtLeastOneValidRecord(sectionKey: string): boolean {
    const formArray = this.form.get(sectionKey) as FormArray;

    return formArray.controls.some(group => {
      const formGroup = group as FormGroup;
      return Object.keys(formGroup.controls).some(key => {
        const control = formGroup.get(key);
        return control?.value; // Check if the control has a value
      });
    });
  }

  getOwnerType() {
    const ownership = this.form.get('business_info.ownership')?.value;
    return ownership?.name;
  }

  // Add a method to handle opening the dialog
  openAddRowDialog(section: FormSection): void {
    const sectionKey = this.formatSectionTitle(section);
    const sectionArray = this.form.get(sectionKey) as FormArray;

    const dialogRef = this.dialog.open(AddRowModalComponent, {
      width: section?.meta?.modalConfig?.width ?? '700px',
      height: section?.meta?.modalConfig?.height ?? '',
      panelClass: 'custom-modal',
      data: { refID: this.refID, mode: 'add', section, sectionArray, disableOwnerPercentage: (section?.meta?.controlValue && this.getOwnerType() === section?.meta?.controlValue) }, // Pass both the section and the FormArray
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.isEmptyFlag = false;
        const newGroup = this.fb.group({});
        Object.keys(result).forEach(key => {
          newGroup.addControl(key, this.fb.control(result[key]));
        });

        if (this.isFirstGroupEmpty(sectionKey)) {
          this.clearFirstIndex(sectionKey);
        }

        sectionArray.push(newGroup); // Add the new group to the FormArray
      }
    });
  }

  editRow(section: FormSection, index: number): void {
    const sectionKey = this.formatSectionTitle(section);
    const sectionArray = this.form.get(sectionKey) as FormArray;
    const selectedGroup = sectionArray.at(index) as FormGroup;
    const sectionArrayCopy = this.cloneFormArray(this.form.get(sectionKey) as FormArray);

    // If inline edit is disabled, open the modal for editing
    const dialogRef = this.dialog.open(AddRowModalComponent, {
      width: section?.meta?.modalConfig?.width ?? '700px',
      height: section?.meta?.modalConfig?.height ?? '',
      data: {
        refID: this.refID,
        mode: 'edit',
        section,
        sectionArray: sectionArrayCopy,
        selectedGroup,
        editSectionArray: sectionArray,
        disableOwnerPercentage: (section?.meta?.controlValue && this.getOwnerType() === section?.meta?.controlValue)
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateFormArray(result, sectionKey);
        // sectionArray.setControl(index, result); // Update the FormArray with the edited data
      }
    });
  }

  viewRowSection(section: FormSection, index: number): void {
    const sectionKey = this.formatSectionTitle(section);
    const sectionArray = this.form.get(sectionKey) as FormArray;
    const selectedGroup = sectionArray.at(index) as FormGroup;

    // If inline edit is disabled, open the modal for editing
    const dialogRef = this.dialog.open(AddRowModalComponent, {
      width: section?.meta?.modalConfig?.width ?? '700px',
      height: section?.meta?.modalConfig?.height ?? '',
      panelClass: 'custom-modal',

      data: {
        mode: 'view',
        section,
        sectionArray: sectionArray,
        selectedGroup
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  private cloneFormArray(formArray: FormArray): FormArray {
    const clonedArray = this.fb.array([]);
    formArray.controls.forEach(control => {
      const clonedGroup: any = this.fb.group({});
      Object.keys(control.value).forEach(key => {
        clonedGroup.addControl(key, this.fb.control(control.get(key)?.value));
      });
      clonedArray.push(clonedGroup);
    });
    return clonedArray;
  }

  updateFormArray(updatedData: any, sectionKey: string): void {
    const sectionArray = this.form.get(sectionKey) as FormArray;

    // Find the index of the selected group (if editing)
    const index = sectionArray.controls.findIndex(group =>
      this.isFormGroupEqual(group as FormGroup, updatedData)
    );

    sectionArray.at(index).patchValue(updatedData, { emitEvent: false });


    // if (index > -1) {
    //   // If the selected group was found, update it with the new data
    //   sectionArray.at(index).patchValue(updatedData, {emitEvent: false});
    // } 
    /* else {
       // Otherwise, add the new data as a new group
       const newGroup = this.fb.group({});
       Object.keys(updatedData).forEach(key => {
         newGroup.addControl(key, this.fb.control(updatedData[key]));
       });
       sectionArray.push(newGroup);
     } */
  }

  private isFormGroupEqual(group: FormGroup, data: any): boolean {
    return Object.keys(data).every(key => group.get(key)?.value === data[key]);
  }

  removeFormGroupFromSection(section: any, index: number) {
    const sectionKey = section?.key;
    const layout = section?.layout ?? 'form';
    const title = section?.title ?? 'item';
    this._modal.openDeleteDialog({ data: { paragraph: `<p class="">Do you want to delete this ` + title + `?` } }).afterClosed().subscribe(result => {
      if (result) {
        const sectionInfo = this.sections.find((x: any) => x?.meta?.triggerOverallPercentageCalculation);

        if (sectionInfo) {
          sectionInfo.meta.disableActionBtn = false;
        }

        const sectionArray = this.form.get(sectionKey) as FormArray;

        if (sectionArray.length > 1) {
          sectionArray.removeAt(index);
        } else if (layout == 'table' && sectionArray.length == 1) {
          this.emptyFormArrayValues(sectionKey);
        }
      }
    })
  }

  emptyFormArrayValues(sectionKey: string): void {
    const sectionArray = this.form.get(sectionKey) as FormArray;

    sectionArray?.controls.forEach(control => {
      if (control instanceof FormGroup) {
        Object.entries(control.controls).forEach(([key, childControl]) => {
          childControl?.setValue(key === 'isEmptyFlag' ? true : '');
        });
      } else {
        control?.setValue('');
      }
    });
  }

  createQueryParam(field: any, group: any) {
    const obj: any = {};
    if (Array.isArray(field.dependsOn)) {
      for (const dependsOn of field.dependsOn) {
        if (group.get(dependsOn)?.value) {
          obj[dependsOn] = group.get(dependsOn)?.value?.id;
        }
      }
    } else if (group.get(field.dependsOn)?.value) {
      obj[field.dependsOn] = group.get(field.dependsOn)?.value?.id;
    }
    const params = new URLSearchParams(obj);
    return '?' + params.toString();
  }

  fetchOptionsFromApi(field: any, section: any, parentValue?: any, group?: any) {
    let apiEndpoint: any = this.prepareApiEndpoint(field.apiEndpoint, parentValue, field, group);
    const fieldName = this.getFieldName(field, section);

    this._http.requestCall(apiEndpoint, ApiMethod.GET).subscribe((options: any) => {
      this.updateFieldOptions(field, options);
      this.validateControlValue(field, fieldName, group);
    });
  }

  private prepareApiEndpoint(apiEndpoint: string, parentValue: any, field: any, group: any): string {
    apiEndpoint = apiEndpoint?.replace(":refID", this.refID ?? 0) ?? "";
    const dependentField = field.dependsOn;

    if (parentValue && dependentField) {
      let param = this.createQueryParam(field, group);
      apiEndpoint += param;
    }
    return apiEndpoint;
  }

  private getFieldName(field: any, section: any): string {
    const sectionKey = section.key ?? "";
    return section.sectionType === 'single' ? `${sectionKey}.${field.name}` : field.name ?? "";
  }

  private updateFieldOptions(field: any, options: any): void {
    field.options = options?.data || [];
  }

  private validateControlValue(field: any, fieldName: string, group: any): void {
    const controlValue = group?.get(fieldName)?.value;

    if (controlValue?.id && !this._common.findById(field.options, controlValue.id)) {
      group.get(fieldName)?.setValue(null);
    }
  }

  findFieldByName(name: string): FormField | undefined {
    const isFieldMatch = (field: FormField, section: FormSection, sectionType: string) =>
      sectionType === 'single' ? `${section.key}.${field.name}` === name : field.name === name;

    for (let section of this.sections) {
      const sectionType = section.sectionType;
      for (let field of section.fields) {
        if (isFieldMatch(field, section, sectionType)) {
          return field;
        }
      }
    }
    return undefined;
  }

  getErrorMessage(controlName: string, label: string, group?: FormGroup): string | null {
    const control = group ? group.get(controlName) : this.form.get(controlName);
    const field = this.findFieldByName(controlName);

    if (control && field?.validators) {
      for (const validator of field.validators) {
        if (!validator.message && validator.name == 'required' && control.hasError(validator.name)) {
          return `${label} is required`;
        } else if (control.hasError(validator.name)) {
          return validator.message ?? 'Something is missing!';
        }
      }
    }

    return null;
  }

  get isvalidForm(): boolean {
    return this.form.valid && this.dynamicFormField?.dynamicLocation.location.valid
  }

  hitValidation() {
    if (this.form.invalid || this.dynamicFormField?.dynamicLocation.location.invalid) {
      this.validationService.markAllAsTouched(this.dynamicFormField?.dynamicLocation.location);
      this.validationService.markAllAsTouched(this.form);
    }
  }

  validateForm() {
    this.isSubmitted = true;
    if (this.form.invalid) {
      if (this.dynamicFormField?.dynamicLocation?.location?.invalid)
        this.validationService.markAllAsTouched(this.dynamicFormField?.dynamicLocation.location);

      this.validationService.markAllAsTouched(this.form);
      return;
    }

    const formData = this.extractFormData();
    this.formSubmit.emit(formData);
  }

  convertFieldToSection(field: FormField, sectionKey: string, hideLabel?: boolean): FormSection {
    field.labelReq = (hideLabel) ? false : field?.labelReq;
    return {
      key: sectionKey,
      title: field.label || field.name,
      fields: [field],
      columns: 1,
      sectionType: 'multiple', // Assuming single field layout
      layout: 'table' // Default layout for single field
    };
  }

  // Helper function to get the value based on field type
  getFieldValue(field: any, value: any, isDisplay?: boolean, extractInputDD?: boolean) {
    if (field.type === 'select' || extractInputDD) {
      return this.handleSelectField(field, value, isDisplay);
    } else if (field.type === 'combobox') {
      return this.handleComboboxField(value, isDisplay);
    } else if (field.type === 'date' && value) {
      return this.formatDateField(value);
    } else if (field.type === 'checkbox') {
      return this.handleCheckboxField(value);
    } else if (field.type === 'location_field') {
      return this.parseLocationField(value);
    } else if (this.processAttachment && (field.type === 'file' || field.type === 'multi_file')) {
      return this.handleFileField(field, value);
    }

    value = (field?.meta?.inputSuffixText) ? value + " " + field?.meta?.inputSuffixText : value;
    return value;
  }

  parseLocationField(value: any) {
    if (!this.dynamicFormField?.dynamicLocation && value && value?.country) {
      return this._common.getLastNestedId(value);
    }

    const selectedLocation = this.dynamicFormField?.dynamicLocation?.getLeastRec();
    if (selectedLocation) {
      const rawValue = this.dynamicFormField?.dynamicLocation?.getLocationForm?.get(selectedLocation?.controlName)?.getRawValue();

      let locationId = "";

      if (Array.isArray(rawValue)) {
        locationId = rawValue[0]?.id || "";
      } else if (rawValue && typeof rawValue === 'object') {
        locationId = rawValue?.id || "";
      }

      return locationId;
    }

    return value;
  }

  private handleSelectField(field: any, value: any, isDisplay?: boolean) {
    if (field?.meta?.multiple && Array.isArray(value)) {
      return isDisplay
        ? value.map((item: any) => item?.display_name ?? item?.name ?? "-").join(', ')
        : value.map((item: any) => item?.id) || null;
    } else {
      return (isDisplay ? (value?.display_name ?? value?.name ?? "-") : value?.id) ?? null;
    }
  }

  private handleComboboxField(value: any, isDisplay?: boolean) {
    return (isDisplay ? (value?.display_name ?? value?.name ?? "-") : value?.display_name ?? value) ?? null;
  }

  private formatDateField(value: any) {
    return formatDate(value, this.dateFormat, 'en-US');
  }

  private handleCheckboxField(value: any) {
    return value || false;
  }


  private handleFileField(field: any, value: any) {
    value = (field.type === 'file' && !Array.isArray(value)) ? [value] : value;
    const attachments = Array.isArray(value) ? value : [];

    const uniqueAttachments = attachments.length
      ? attachments.map((file: any) => this.processFileAttachment(file))
      : [];

    return uniqueAttachments.filter((attachment: any) => {
      if (typeof attachment === 'number') return true;
      return attachment && typeof attachment === 'string' && attachment.trim() !== "";
    });
  }

  private processFileAttachment(file: any) {
    if (file?.is_copy && file?.id) return file?.id;

    if (!file?.name || !file.type || (this.mode !== 'add' && file.id)) {
      return "";
    }

    const uniqueName = this._common.generateUniqueFileName(file);
    this.attachmentList[uniqueName] = file;
    return uniqueName || "";
  }


  // Method to extract all form data including sectionType sections
  extractFormData() {
    const extractedData: any = {};
    this.attachmentList = {};

    this.sections.forEach(section => {
      const sectionKey = this.formatSectionTitle(section);

      if (section.sectionType === 'multiple') {
        extractedData[sectionKey] = this.extractMultipleSection(section, sectionKey);
      } else if (section.sectionType === 'root') {
        this.extractRootSection(section, extractedData);
      } else {
        extractedData[sectionKey] = this.extractSingleSection(section, sectionKey);
      }
    });

    return extractedData;
  }

  private extractMultipleSection(section: any, sectionKey: string) {
    const formArray = this.form.get(sectionKey) as FormArray;
    if (!formArray || (formArray.length === 1 && this.isFirstGroupEmpty(sectionKey))) {
      return [];
    }

    return formArray.getRawValue().map((group: any) => {
      const transformedGroup: any = {};

      section.fields.forEach((field: any) => {
        if (this.isValidKeyToExtract(field.name, group[field.name])) {
          transformedGroup[field.name] = this.getFieldValue(field, group[field.name]);
        }
        this.setDeletedAttachments(transformedGroup, group, field.type, field.name);
        this.appendPhoneCode(transformedGroup, field, group);
      });

      return transformedGroup;
    });
  }

  private extractRootSection(section: any, extractedData: any) {
    section.fields.forEach((field: any) => {
      const fieldValue = this.form.get(field.name)?.value;

      if (this.isValidKeyToExtract(field.name, fieldValue)) {
        extractedData[field.name] = this.getFieldValue(field, fieldValue);
      }

      if (this.mode === 'edit' && (field.type === 'multi_file' || field.type === 'file')) {
        extractedData['deleted_' + field.name] = this.form.get('deleted_' + field.name)?.value;
      }

      this.appendPhoneCode(extractedData, field, this.form);
    });
  }

  private extractSingleSection(section: any, sectionKey: string) {
    const sectionData: any = {};

    section.fields.forEach((field: any) => {
      const fieldPath = `${sectionKey}.${field.name}`;
      const fieldValue = this.form.get(fieldPath)?.value;

      if (this.isValidKeyToExtract(field.name, fieldValue)) {
        sectionData[field.name] = this.getFieldValue(field, fieldValue);
      }

      if (this.mode === 'edit' && (field.type === 'multi_file' || field.type === 'file')) {
        sectionData['deleted_' + field.name] = this.form.get(`${sectionKey}.deleted_${field.name}`)?.value;
      }

      this.appendPhoneCode(sectionData, field, this.form);
    });

    return sectionData;
  }


  isValidKeyToExtract(fieldName: any, value: any) {
    if (this.mode == 'edit' || fieldName !== 'id' || (fieldName === 'id' && this.mode == 'add' && value)) {
      return true;
    }

    return false;
  }

  appendPhoneCode(outputArr: any, field: any, group: any, sectionKey?: string) {
    const type = field.type ?? '';
    const key = type === 'phone_field' ? 'country_code' : field.altName;

    // Initialize section key in outputArr if necessary
    if (sectionKey && !outputArr[sectionKey]) {
      outputArr[sectionKey] = {};
    }

    const target = sectionKey ? outputArr[sectionKey] : outputArr;

    // Append values based on field type
    if (type === 'phone_field') {
      target[key] = this.staticText.common.country_code;
    } else if (type === 'input_dropdown') {
      const value = sectionKey
        ? this.form.get(`${sectionKey}.${key}`)?.value
        : group[key];
      target[key] = this.getFieldValue(field, value, false, true);
    }

    return outputArr;
  }


  setDeletedAttachments(transformedGroup: any, group: any, type: string, name: string) {
    if (this.mode === 'edit' && (type === 'multi_file' || type === 'file')) {
      transformedGroup['deleted_' + name] = group['deleted_' + name];
    }

    return transformedGroup;
  }

  // Method to disable all controls within a FormArray or FormGroup
  private disableAllFormControls(control: AbstractControl): void {
    if (control instanceof FormGroup) {
      // Loop through all controls in the FormGroup and disable them
      Object.keys(control.controls).forEach(key => {
        const childControl = control.get(key);
        if (childControl) {
          this.disableAllFormControls(childControl); // Recursively disable nested FormGroups and FormArrays
        }
      });
    } else if (control instanceof FormArray) {
      // Loop through each control in the FormArray
      control.controls.forEach((childControl, index) => {
        this.disableAllFormControls(childControl); // Recursively disable each control in the FormArray
      });
    } else {
      control.disable(); // Disable individual control
    }
  }

  // Method to enable all controls within a FormArray or FormGroup
  private enableAllFormControls(control: AbstractControl): void {
    if (control instanceof FormGroup) {
      // Loop through all controls in the FormGroup and enable them
      Object.keys(control.controls).forEach(key => {
        const childControl = control.get(key);
        if (childControl) {
          this.enableAllFormControls(childControl); // Recursively enable nested FormGroups and FormArrays
        }
      });
    } else if (control instanceof FormArray) {
      // Loop through each control in the FormArray
      control.controls.forEach((childControl, index) => {
        this.enableAllFormControls(childControl); // Recursively enable each control in the FormArray
      });
    } else {
      control.enable(); // Enable individual control
    }
  }

  viewLocationDetails(details: any): any {
    const locationDetails = this._common.transformData(details?.country, 'country');
    const locationdetails = this._common.flattenJson(locationDetails, true);
    this.locationdetails = this._lodash.groupBy(locationdetails, 'key');
    let groupDetails: any = [];

    for (const element of Object.keys(this.locationdetails)) {
      let obj: any = {};
      obj.display_name = element;
      obj.group = this.locationdetails[element];
      obj.concatName = this.locationdetails[element]?.map((x: any) => x.display_name)?.toString();
      groupDetails.push(obj);
    }

    return groupDetails
  }

  getSectionSubTitle(section: any, id: any) {
    let title = "";
    if (section.sectionType == 'multiple') {
      let matchObj = this.findFirstMatch(section?.meta?.data, 'id', id);
      title = matchObj?.display_name || "";
    }

    return title;
  }

  findFirstMatch(array: any[], key: string, value: any) {
    return array.find(item => item[key] === value);
  }

  view_file(data: any) {
    if (data?.length) {
      const attachments = data.map((item: any) => item?.attachment);
      if (attachments.length) {
        this.viewFilePreview(attachments);
      } else {
        this._snackBar.loadSnackBar(FailedMessage.FILE_NOT_FOUND_MSG, colorCodes.WARNING);
      }
    }
    else {
      this.viewFilePreview(data?.attachments);
    }
  }

  viewFilePreview(filepath: any) {
    this._common.filePreviewModal(filepath).subscribe((result) => {
    })
  }

  getFormKey(section: any, field: any, altKey: boolean = false) {
    const fieldName = altKey ? field.altName : field.name;

    if (section.sectionType === 'single') {
      return section.key + '.' + fieldName;
    } else if (section.sectionType === 'root') {
      // Adjust the key as needed for root
      return fieldName; // Or any other logic
    }
    return section.key + '.' + fieldName;
  }



}
