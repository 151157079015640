import { Injectable } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class DynamicFormValidationService {
  constructor() {}

  markAllAsTouched(form: FormGroup): void {
    Object.keys(form.controls).forEach(key => {
      const control = form.get(key);
      if (control instanceof FormArray) {
        control.controls.forEach(group => {
          this.markGroupAsTouched(group as FormGroup);
        });
      } else if (control instanceof FormGroup) {
        this.markGroupAsTouched(control);
      } else {
        control?.markAsTouched();
        control?.updateValueAndValidity({emitEvent: false});
      }
    });
  }

  private markGroupAsTouched(group: FormGroup): void {
    Object.keys(group.controls).forEach(fieldKey => {
      const fieldControl = group.get(fieldKey);
      if (fieldControl instanceof FormArray) {
        fieldControl.controls.forEach(arrayGroup => {
          this.markGroupAsTouched(arrayGroup as FormGroup);
        });
      } else if (fieldControl instanceof FormGroup) {
        this.markGroupAsTouched(fieldControl);
      } else {
        fieldControl?.markAsTouched();
        fieldControl?.updateValueAndValidity({emitEvent: false});
      }
    });
  }
}
