<section class="add-row-section">
  <div class="add-row-card-container d-flex flex-column">
    <!-- header part -->
    <div class="add-row-header d-flex flex-column justify-content-center">
      <div class="container add-row-title">
        <div class="d-flex flex-row justify-content-between align-items-center">

          <label class="fw-600 fs-20 modalTitle"> {{ section?.meta?.modalTitle || title}} </label>
          <img class="cursor-pointer" src="./assets/images/common/icons/close_black.svg" alt="close"
            (click)="onCancel()" />
        </div>
      </div>
    </div>

    <!-- body part -->
    <div mat-dialog-content *ngIf="mode !=='view'; else viewModalTemplate">
      <div class="add-row-body ">
        <div class="d-flex col_data  flex-column px-0 p-3 pb-0">
          <form [formGroup]="form" (ngSubmit)="onSave()">
            <app-dynamic-form-field [isDialogue]="true" [group]="form" #dynamicFormField [section]="section"
              [mode]="mode"></app-dynamic-form-field>
          </form>
        </div>
      </div>
    </div>

    <ng-template #viewModalTemplate>
      <div class="add-row-body m-3">
        <div class="d-flex flex-column px-2 p-3 pb-0 pt-0 w-100">
          <div class="row">
            <ng-container *ngFor="let field of section.fields">

              <ng-container *ngIf="field.type === 'location_field'; else fileViewTemplate">

                <ng-container *ngFor="let location of viewLocationDetails( form?.get(field?.name)?.value)">
                  <div class="flex-column view-column-cell with-top-border col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <label for="" class="form-label color-primary fs-12 view-field-label"><span
                        [innerHTML]="location.display_name"></span></label>
                    <p class="color-darkgrey fw-600 view_text_wrap" title="{{ location.display_name || '-' }}">
                      {{ location?.concatName || '-'}}
                    </p>
                  </div>
                </ng-container>
              </ng-container>


              <ng-template #fileViewTemplate>

                <div *ngIf="field.type === 'multi_file' || field.type === 'file'; else customFieldTemplate"
                  class="flex-column view-column-cell with-top-border col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <label class="view-field-label">{{ field.label }}</label>
                  <div class="view-field-value ng-star-inserted"> <a class="attachment_link"
                      *ngIf="form.get(field.name)?.value?.length;else fileBlobTemplate"
                      (click)="view_file(form.get(field.name)?.value)"><img
                        src="../../../../../assets/images/common/icons/view_table.svg" alt="">
                      &nbsp; &nbsp;{{ form.get(field.name)?.value?.length || 0 }}
                      attachment(s)</a>
                  </div>
                  <ng-template #fileBlobTemplate>
                    <ng-container *ngIf="form.get(field.name)?.value"> 1 attachment(s) </ng-container>
                  </ng-template>
                </div>
              </ng-template>


              <ng-template #customFieldTemplate>
                <div *ngIf="field.type == 'template'; else customPreviewImageFieldTemplate">
                  <!-- Custom template rendering using templateRef directly from the form config -->
                  <ng-container *ngTemplateOutlet="field.templateRef; context: field.context"></ng-container>
                </div>
              </ng-template>

              <ng-template #customPreviewImageFieldTemplate>
                <div *ngIf="field.type == 'preview_image'; else otherFieldTemplate"
                  class="flex-column view-column-cell with-top-border col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <!-- Custom template rendering using templateRef directly from the form config -->
                  <ng-container *ngTemplateOutlet="field.templateRef; context: field.context"></ng-container>
                </div>
              </ng-template>

              <ng-template #otherFieldTemplate>
                <div *ngIf="field.type !== 'hidden'"
                  class="flex-column view-column-cell with-top-border col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <label class="view-field-label">{{ field.label }}</label>
                  <div class="view-field-value ng-star-inserted"> {{getFieldValue(field,
                    this.form.get(field.name)?.value, true)}}
                    <span *ngIf="field.type=='input_dropdown'">
                      {{ this.form.get(field.altName)?.value?.display_name ?? ""}}
                    </span>
                  </div>
                </div>
              </ng-template>

            </ng-container>
          </div>
        </div>
      </div>
    </ng-template>
    <div class="major-footer">
      <div class="major-modal-actions  cursor-pointer p-3">
        <div class="major-modal-close-button px-1">
          <button
            class="btn_default_user_small mdc-button mdc-button--outlined mat-mdc-outlined-button mat-unthemed mat-mdc-button-base"
            mat-stroked-button (click)="onCancel()"> <span *ngIf="mode =='view'; else canceledit">Close</span>
            <ng-template #canceledit>Cancel</ng-template></button>
        </div>
        <div *ngIf="mode!=='view'" class="major-modal-event-button px-1">
          <button mat-raised-button
            class="btn_login_user_small mdc-button mdc-button--raised mat-mdc-raised-button mat-unthemed mat-mdc-button-base"
            (click)="onSave()">
            <div *ngIf=" this.mode === 'edit'; else addUser">


              <span class="text-light modalTitle "> Update Details
              </span>

            </div>
            <ng-template #addUser>
              <span class="text-light modalTitle "> <ng-container
                  *ngIf="section?.meta?.btnActionText">Add</ng-container> {{
                section?.meta?.btnActionText ||section?.meta?.modalTitle || title}}</span>

            </ng-template>
          </button>
        </div>
      </div>
    </div>


  </div>
</section>