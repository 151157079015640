<div></div>

<div class="row mx-auto">
  <ng-container *ngFor="let field of section.fields">
    <div class="mb-3"
      [ngClass]="(field.type === 'location_field' || field.type === 'template') ? 'col-xl-12 col-md-12 col-lg-12 col-sm-12 col-12' : getColumnClasses(field?.columns || section.columns)"
      *ngIf="field.type !== 'hidden'; else hiddenTemplate" class="{{field?.class}}">
      <ng-container [ngSwitch]="field.type">

        <custom-input *ngIf="field.type === 'text' || field.type === 'password'" [inputFormGroup]="group"
          [inputDisableState]="field?.readonly" [inputFormControlName]="field.name" [inputType]="field.type"
          [inputRequiredStatus]="field?.required" [inputAppearance]=""
          [inputLabel]="(field?.labelReq) ? field.label : ''" [inputPlaceholderLabel]="field.label"
          [inputErrorLabel]="getErrorMessage(field.name, field.label, group)"
           [inputSuffixText]="field?.meta?.inputSuffixText" [inputSuffixTextClass]="field?.meta?.inputSuffixTextClass ?? 'custom_suffix_txt'"
          [inputPrefixText]="field?.meta?.inputPrefixText" [inputPrefixTextClass]="field?.meta?.inputPrefixTextClass">
        </custom-input>

        <!-- TextArea -->
        <custom-text-area *ngSwitchCase="'textarea'" [inputTextAreaFormGroup]="group"
          [inputTextAreaReadOnly]="field?.readonly" [inputTextAreaFormControlName]="field.name"
          [inputTextAreaLabel]="(field?.labelReq) ? field.label : ''" [inputTextAreaPlaceholderLabel]="field.label"
          [inputTextAreaErrorLabel]="getErrorMessage(field.name, field.label, group)" inputTextAreaMinRowSize="2"
          [inputTextAreaAppearance]="">
        </custom-text-area>

        <custom-date-picker *ngSwitchCase="'date'" [inputDatePickerFormGroup]="group"
          [inputDatePickerFormControlName]="field.name" [inputDatePickerRequiredStatus]="field?.required"
          [inputDatePickerPlaceholderLabel]=" field.label" [inputDatePickerAppearance]=""
          [inputDatePickerMaxDate]="field?.meta?.max_date" [inputDatePickerMinDate]="field?.meta?.min_date"
          [inputDatePickerErrorLabel]="getErrorMessage(field.name, field.label, group)"
          [inputDisableState]="field?.readonly">
        </custom-date-picker>

        <!-- Dropdown -->
        <!-- <custom-select *ngIf="field.type=='select' && !field?.meta?.multiple" [inputSelectArray]="findFieldByName(field.name)?.options" [inputSelectFormContolName]="field.name"
          [inputSelectFormGroup]="group" [inputSelectRequiredStatus]="(field?.required) ? true : ''"
          [inputSelectMutipleOptionStatus]="field?.meta?.multiple" [inputSelectGroupBy]="field?.meta?.groupBy"
           [inputAppearance]="" [inputSelectPlaceholderLabel]="(field?.labelReq) ? field.label : ''" 
           [inputSelectErrorLabel]="getErrorMessage(field.name, field.label, group)">
        </custom-select>  -->

        <custom-autocomplete *ngIf="field.type=='select' && !field?.meta?.multiple" [inputAutocompleteFormGroup]="group"
          [inputAutocompleteAppearance]="" [inputAutocompleteFormControlName]="field.name"
          [inputAutocompleteRequiredStatus]="(field?.required) ? true : ''"
          [inputAutocompleteLabel]="(field?.labelReq) ? field.label : ''"
          [inputAutocompletePlaceholderLabel]="field.label"
          [requireSelection]="true" [ondemand]= "field?.meta?.ondemand" [ondemandAPI]= "field?.meta?.ondemand ? field?.apiEndpoint : ''" [ondemandQueryParamKey]= "field?.meta?.ondemandSearchKey"
          [inputAutoCompleteErrorLabel]="getErrorMessage(field.name, field.label, group)" [autocompletevaluekey]="id"
          [inputAutocompleteArray]="findFieldByName(field.name)?.options" [inputAutoCompletereadonly]="field?.readonly">
        </custom-autocomplete>

        

        <custom-autocomplete *ngIf="field.type=='combobox'" [inputAutocompleteFormGroup]="group"
          [inputAutocompleteAppearance]="" [inputAutocompleteFormControlName]="field.name"
          [inputAutocompleteRequiredStatus]="(field?.required) ? true : ''"
          [inputAutocompleteLabel]="(field?.labelReq) ? field.label : ''"
          [inputAutocompletePlaceholderLabel]="field.label"
          [inputAutoCompleteErrorLabel]="getErrorMessage(field.name, field.label, group)" [autocompletevaluekey]="id"
          [inputAutocompleteArray]="findFieldByName(field.name)?.options" [inputAutoCompletereadonly]="field?.readonly">
        </custom-autocomplete>

        <!-- Radio -->
        <custom-radio *ngSwitchCase="'radio'" [inputRadioFormGroup]="group" [inputRadioDisableState]="field?.readonly"
          [inputRadioFormControlName]="field.name" [inputRadioRequiredStatus]="field?.required"
          [radioLabel]="(field?.labelReq) ? field.label : ''" [inputRadioOptions]="field.options"
          [inputRadioErrorLabel]="getErrorMessage(field.name, field.label, group)">
        </custom-radio>

        <!-- Checkbox -->
        <custom-checkbox *ngSwitchCase="'checkbox'" [inputCheckBoxLabel]="field.label"
          [inputCheckBoxFormControlName]="field.name" [inputCheckBoxFormGroup]="group" #dynamicCheckbox
          [inputCheckBoxDisableState]="field?.readonly" [inputCheckBoxRequiredStatus]="field?.required"
          [inputCheckBoxLabel]="(field?.labelReq) ? field.label : ''" (checkboxChange)="onFieldChange($event, field)">
        </custom-checkbox>

        <!-- MultiSelect -->
        <custom-multiselect *ngIf="field.type=='select' && field?.meta?.multiple" [inputSelectArray]="field.options"
          [inputSelectDisableState]="field?.readonly ? true : false" [inputSelectFormContolName]="field.name"
          [inputSelectFormGroup]="group" [inputSelectRequiredStatus]="field.required || false"
          [inputSelectLabel]="(field?.labelReq) ? field.label : ''" [inputSelectPlaceholder]="field.label"
          [inputSelectErrorLabel]="getErrorMessage(field.name, field.label, group)"
          [inputSelectOptionKey]="field?.meta?.optionKey" [inputSelectOptionValue]="field?.meta?.optionValue">
        </custom-multiselect>

        <!-- File Upload -->
        <custom-file-attachment class="file_pos" *ngSwitchCase="'file'" [inputFileFormGroup]="group"
          [inputFileFormControlName]="field.name"
          [inputFileAcceptExtenstions]="field?.meta?.allowed_type || '.jpg, .jpeg, .png, .doc, .pdf'"
          [maxFileSize]="field?.meta?.max_file_size ?? 5"
          [inputFileLabel]="(field?.labelReq) ? field.label : ''" inputFileUploadLabel="{{field.label}}"
          [assignFileLink]="filePath" [fileName]="showAttachment" [customDownloadDelete]="true"
          (inputFileOnSelect)="selectSingleFile($event, group, field.name)" 
          (inputFileOnDelete)="onDeleteFile($event, group, field.name, true)"
          [inputFileErrorLabel]="getErrorMessage(field.name, field.label, group)">
        </custom-file-attachment>

        <!-- Multi File Upload -->
        <app-custom-multi-fileupload class="file_pos" *ngSwitchCase="'multi_file'" [inputFileFormGroup]="group"
          [disableFileUpload]="disableForm" [inputFileFormControlName]="field.name"
          [maxFileSize]="field?.meta?.max_file_size ?? 5"
          [maxFile]="field?.meta?.max_file ?? 5"
          [inputFileAcceptExtenstions]="field?.meta?.allowed_type || '.jpg, .jpeg, .png, .doc, .pdf'"
          [inputFileLabel]="(field?.labelReq) ? field.label : ''" inputFilePlaceholderLabel="{{field.label}}"
          [assignFileLink]="filePath" [fileName]="showAttachment" [customDownloadDelete]="true"
          (inputFileOnSelect)="selectFile($event, group, field.name)"
          (inputFileOnDelete)="onDeleteFile($event, group, field.name)"
          [inputFileErrorLabel]="getErrorMessage(field.name, field.label, group)">
        </app-custom-multi-fileupload>

        <!-- Phone Field -->
        <custom-input-dropdown *ngSwitchCase="'phone_field'" [inputDropdownFormGroup]="group"  [inputDropdownFormControlName]="field?.altName"
          [inputFormControlName]="field.name" [inputDropdownRequiredStatus]="field?.required" 
          [inputDropdownPreffix]="true" [inputRequiredStatus]="true" inputType="text"
          [inputMaxLength]="phoneNumberMaxLength" [inputDropdownreadonly]="field?.readonly"
          [inputOnKeyPressFunction]="_common.acceptNumberOnlyEvent" inputAppearance=""
          [inputDropdownArray]="this.countries" [inputDropdownPlaceholder]="staticText?.common?.country_code"
          [inputErrorLabel]="getErrorMessage(field.name, field.label, group)">
        </custom-input-dropdown>

        <!-- Input Dropdown Field -->
        <custom-input-dropdown *ngSwitchCase="'input_dropdown'" [inputDropdownFormGroup]="group" [inputDropdownFormControlName]="field?.altName"
          [inputFormControlName]="field.name" [inputDropdownRequiredStatus]="field?.required"
          [inputDropdownPreffix]="field?.meta?.prependDD" [inputDropdownSuffix]="field?.meta?.appendDD" [inputRequiredStatus]="true" inputType="text"
          [inputMaxLength]="field.maxlength" [inputDropdownreadonly]="field.readonly"
          [inputOnKeyPressFunction]="_common.acceptNumberOnlyEvent" [inputAppearance]=""
          [inputDropdownArray]="field?.options" [inputPlaceholder]="field?.label" [inputDropdownPlaceholder]="field?.meta?.ddPlaceholder" [inputDropdownFilterKey]="field?.meta?.ddFilterKey"
          [inputErrorLabel]="getErrorMessage(field.name, field.label, group)">
        </custom-input-dropdown>

        <!-- Location Field -->
        <div *ngSwitchCase="'location_field'" class="d_block">
          <div class="location_details mt-2">Location Details</div>
          <app-location-form [isDialogue]="isDialogue" [disableField]="disableForm" [config]="field?.meta?.config"
            #dynamicLocation [mode]="mode"></app-location-form>
        </div>

        <div *ngSwitchCase="'template'">
          <!-- Custom template rendering using templateRef directly from the form config -->
           <ng-container *ngTemplateOutlet="field.templateRef; context: field.context"></ng-container>
        </div>

        <div *ngSwitchCase="'preview_image'">
          <!-- Custom template rendering using templateRef directly from the form config -->
           <ng-container *ngTemplateOutlet="field.templateRef; context: field.context"></ng-container>
        </div>

        <!-- Status Toggler Field -->
        <div [formGroup]="group" *ngSwitchCase="'status_toggler'">

          <div *ngIf="field?.labelReq">
            <label class="status-label" [innerHtml]="(field?.labelReq) ? field.label : ''"></label>
          </div>
          <div class="active-inactive">
            <mat-label (click)="updateStatus(group, field.name, true)" [ngClass]="{
              'status-active': group.get(field.name)?.value
            }" class=" px-3 cursor-pointer" [innerHtml]="staticText?.common?.active"></mat-label>
            <mat-label (click)="updateStatus(group, field.name, false)" [ngClass]="{
                  'status-in-active': !group.get(field.name)?.value
                }" class=" px-3  cursor-pointer" [innerHtml]="staticText?.common?.inactive"></mat-label>
            <mat-slide-toggle formControlName="{{field.name}}" style="display: none;"></mat-slide-toggle>
          </div>
        </div>


      </ng-container>
    </div>

    <!-- Hidden -->
    <ng-template #hiddenTemplate>
      <span *ngIf="field.labelReq" class="sub-section-title"> {{ getSectionSubTitle(section,
        group.get(field.name)?.value)}}</span>
      <ng-container [formGroup]="group">
        <input *ngIf="field.type === 'hidden'" type="hidden" [formControlName]="field.name" />
      </ng-container>
    </ng-template>
  </ng-container>
</div>