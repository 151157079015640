import { Component, Input, ViewChild, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule, FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CustomFieldsModule } from '../../mat-elements/custom-fields/custom-fields.module';
import { FormField } from '../form-sections.model'; // Adjust the path as needed
import { PHONE_NUMBER_MAX_LENGTH } from 'src/app/core/services/utils/constants';
import { CommonService } from 'src/app/core/services/common/common.service';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';
import { LocationFormComponent } from 'src/app/shared/components/location-form/location-form.component';
import { CustomCheckboxComponent } from '../../mat-elements/custom-fields/custom-checkbox/custom-checkbox.component';


import { CustomMultiFileuploadComponent } from '../../mat-elements/custom-fields/custom-multi-fileupload/custom-multi-fileupload.component';

@Component({
  selector: 'app-dynamic-form-field',
  styleUrls: ['./dynamic-form-field.component.scss'],
  templateUrl: './dynamic-form-field.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CustomFieldsModule,
    LocationFormComponent,
  ]
})
export class DynamicFormFieldComponent {
  @Input() group!: FormGroup;
  @Input() section: any | undefined;
  @Input() field!: FormField;
  @Input() disableForm: boolean = false;
  @Input() groupIndex: Number = 0;
  @Input() mode: string = 'add';
  @Input() isDialogue:boolean = false;
  phoneNumberMaxLength: number = PHONE_NUMBER_MAX_LENGTH;
  staticText: any = (textConfiguration as any).default;
  countries = [
    { code: '+250', name: 'Rwanda' }
  ];
  @ViewChild('dynamicLocation') dynamicLocation!: LocationFormComponent;

  // @ViewChild('dynamicCheckbox') dynamicCheckbox!: CustomCheckboxComponent;
  @ViewChildren(CustomMultiFileuploadComponent) multiFileUploads!: QueryList<CustomMultiFileuploadComponent>;
  multiFileUploadMap: Map<string, Map<number, CustomMultiFileuploadComponent>> = new Map();


  constructor(
    protected _common: CommonService

  ) {
  }

  ngAfterViewInit() {
    this.multiFileUploads.forEach((component: CustomMultiFileuploadComponent) => {
      const sectionKey = this.section?.key; // Ensure this is available and valid
      const groupIndex: any = this.groupIndex; // Ensure this property is set correctly

      if (sectionKey !== undefined && groupIndex !== undefined) {
        if (!this.multiFileUploadMap.has(sectionKey)) {
          this.multiFileUploadMap.set(sectionKey, new Map());
        }

        const sectionMap = this.multiFileUploadMap.get(sectionKey)!;
        sectionMap.set(groupIndex, component);
      }
    });

  }

  getColumnClasses(columns: number | undefined): string {
    switch (columns) {
      case 1:
        return 'col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12'; // Full width on XL and LG, 6 columns on MD
      case 2:
        return 'col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12';   // 6 columns on XL and LG
      case 3:
        return 'col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12';   // 3 columns on XL and LG
      case 4:
        return 'col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12';   // 4 columns on XL and LG (Default case)
      default:
        return 'col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12';   // Default to 4 columns
    }
  }


  getErrorMessage(controlName: string, label: string): string | null {
    if (!this.group) {
      return null;
    }
    const control = this.group.get(controlName);
    // const control = group ? group.get(controlName) : this.form.get(controlName);
    const field = this.findFieldByName(controlName);

    if (control && field?.validators) {
      for (const validator of field.validators) {
        if (!validator.message && validator.name == 'required' && control.hasError(validator.name)) {
          return `${label} is required`;
        } else if (control.hasError(validator.name)) {
          return validator.message || 'Something is missing!';
        }
      }
    }

    return null;
  }

  findFieldByName(name: string): FormField | undefined {
    for (let field of this.section.fields) {
      if (field.name === name) {
        return field;
      }
    }
    return undefined;
  }

  getFieldNameWithSection(fieldName: string, sectionKey: string, sectionType: string = 'single'): string {
    return (sectionType == 'single') ? `${sectionKey}.${fieldName}` : fieldName;
  }

  getSectionSubTitle(section: any, id: any) {
    let title = "";
    if (section.sectionType == 'multiple') {
      let matchObj = this.findFirstMatch(section?.meta?.data, 'id', id);
      title = matchObj?.display_name || "";
    }

    return title;
  }

  findFirstMatch(array: any[], key: string, value: any) {
    return array.find(item => item[key] === value);
  }

  selectFile(files: any, formGroup: FormGroup, controlName: string): void {
    const control = formGroup.get(controlName);

    if (files && control) {
      // Assuming you want to store the files in the FormControl
      control.setValue(Array.from(files));
    }
  }

  selectSingleFile(files: any, formGroup: FormGroup, controlName: string): void {
    const control = formGroup.get(controlName);
    const deletedControlName = 'deleted_' + controlName;

    if (formGroup.get(deletedControlName)) {
      formGroup.get(deletedControlName)?.setValue(false);
    }

    if (files && control) {
      // Assuming you want to store the files in the FormControl
      control.setValue(files[0]);
    }
  }

  onDeleteFile(file: any, formGroup: FormGroup, controlName: string, isSingle?: false): void {
    const attachmentsControl = formGroup.get(controlName);
    const deletedControlName = 'deleted_' + controlName; // You can adjust the name if needed

    // Check if the deleted_attachments control exists, if not, create it
    if (!formGroup.get(deletedControlName)) {
      formGroup.addControl(deletedControlName, new FormControl([])); // Add the control dynamically
    }

    const deletedAttachmentsControl = formGroup.get(deletedControlName);
    console.log(deletedAttachmentsControl, file);

    if (attachmentsControl && deletedAttachmentsControl) {
      if (isSingle && file) {
        deletedAttachmentsControl.setValue(true);
      } else {
        // Get the current values for attachments and deleted_attachments
        const deletedAttachments = deletedAttachmentsControl.value || [];

        if (file.id) {
          deletedAttachments.push(file.id); // Assuming each file has an ID
        }

        // Update the attachments and deleted_attachments controls
        deletedAttachmentsControl.setValue(deletedAttachments); // Update deleted_attachments control

      }
    }

    console.log('Deleted Attachments:', deletedAttachmentsControl?.value);
  }

  onFieldChange(value: any, field: any) {
    // Check if the field is of type 'checkbox' and perform the clearing logic if needed
    if (field.type === 'checkbox' && field.controlsDisabling) {
      const currentFieldName = field.name;
      this.clearCurrentGroupValues(field?.meta?.excludeFields, value);
    }
  }

  clearCurrentGroupValues(excludedFieldNames: string[], isCheckboxChecked: boolean) {
    const currentGroup = this.group; // Assume this.group is the current FormGroup

    if (currentGroup) {
      Object.keys(currentGroup.controls).forEach(controlName => {
        const control = currentGroup.get(controlName);
        if (control) {
          const field: any = this.findFieldByName(controlName);

          if (!excludedFieldNames.includes(controlName)) {
            // If control is not in the excluded list, disable and clear value
            if (isCheckboxChecked) {
              if (field && field?.type === 'multi_file') {
                setTimeout(() => {
                  this.setMultiFileUploadVisibility(this.section?.key, this.groupIndex, true);
                }, 100)
              }

              control.disable();
              control.reset(); // Clear value

            } else {
              control.enable();
              this.setMultiFileUploadVisibility(this.section?.key, this.groupIndex, false);
            }
          } else {
            // Handle logic for controls in the excluded list
            if (!isCheckboxChecked) {
              // Ensure the control is enabled and optionally apply additional logic
              control.enable();
              this.setMultiFileUploadVisibility(this.section?.key, this.groupIndex, false);
            }
          }
        }
      });
    }
  }

  setMultiFileUploadVisibility(sectionKey: any, groupIndex: any, disabled: boolean) {
    let component: any = null;
    if (sectionKey !== undefined && groupIndex !== undefined) {
      const sectionMap = this.multiFileUploadMap.get(sectionKey);
      component = sectionMap?.get(groupIndex);
    }
    if (component) {
      if (disabled) {
        component.disableFileUpload = true;
        component.clearValue(); // Call the clearValue method
      } else {
        component.disableFileUpload = false;
      }
    }
  }

  updateStatus(group: any, field: any, status: boolean) {
    group.get(field).setValue(status);
  }

}
